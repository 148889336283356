import { render, staticRenderFns } from "./set-virtual.vue?vue&type=template&id=39dd9c6d&scoped=true"
import script from "./set-virtual.vue?vue&type=script&lang=js"
export * from "./set-virtual.vue?vue&type=script&lang=js"
import style0 from "./set-virtual.vue?vue&type=style&index=0&id=39dd9c6d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39dd9c6d",
  null
  
)

export default component.exports