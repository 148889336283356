<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <div class="tabs">
                        <router-link to="/set-jiliangbiao" class="lk">计量表</router-link>
                        <router-link to="/set-sensor" class="lk">传感器</router-link>
                        <router-link to="" class="lk cur">虚拟表</router-link>
                        <router-link to="/set-outline" class="lk">离线表</router-link>
                        <router-link to="/set-outlineFile" class="lk">离线表文件</router-link>
                        <router-link to="/set-gaugesManage" class="lk">绑定数据点</router-link>
                    </div>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <el-form ref="form" class="top-form" :model="form">
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item>
                                    <el-select v-model="equimentId" placeholder="选择设备">
                                        <el-option v-for="item in equiments" :key="item.value" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="未选择" value="-1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="20">
                                <div class="flex flex-pack-justify">
                                    <button class="ann annbg1" @click="getVirtualMeters">
                                        查询
                                    </button>
                                    <button class="ann annbg3" @click="onAdd()">
                                        添加虚拟表
                                    </button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column prop="id" width="60"  label="ID">
                            </el-table-column>
                            <el-table-column prop="name" label="名称"> </el-table-column>
                            <el-table-column prop="des" label="描述"> </el-table-column>
                            <el-table-column prop="powerCategoryName" label="能源类型">
                            </el-table-column>
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <span class="lk c-main" @click="handleEdit(scope.$index, scope.row)">修改</span>
                                    <span class="lk c-red" @click="handleDelete(scope.$index, scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" :current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="pageInfo.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 添加虚拟表 -->
        <el-dialog :visible.sync="addVirtualPop" width="50%">
            <div class="pop">
                <div class="toptt">
                    <span class="backbtn">添加虚拟表</span>
                    <i class="el-icon-close close" @click="addPop = false"></i>
                    <div class="dix"></div>
                </div>
                <div class="detail">
                    <div class="flex">
                        <div class="lbox">
                            <el-form ref="form" :model="addVirtualForm" label-width="95px">
                                <el-form-item label="名称:">
                                    <el-input v-model="addForm.name"></el-input>
                                </el-form-item>
                                <el-form-item label="能源分类:">
                                    <el-select v-model="addForm.type" placeholder="请选择能源分类">
                                        <el-option v-for="item in energyTypeList" :key="item.value" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="未选择" value="-1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="表达式:">
                                    <el-input type="textarea" v-model="addForm.biaodashi" :rows="2"></el-input>
                                </el-form-item>
                                <el-form-item label="所属设备:">
                                    <el-select v-model="addForm.shebei" multiple placeholder="选择所属设备">
                                        <el-option v-for="item in equiments" :key="item.value" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="未选择" value="-1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="描述:">
                                    <el-input type="textarea" v-model="addForm.miaoshu" :rows="3"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="rbox flex-1">
                            <div class="table">
                                <div class="thead">
                                    <div class="th w1">变量名</div>
                                    <div class="th w2">计量表类型</div>
                                    <div class="th w3">计量表名</div>
                                    <div class="th w4">操作</div>
                                </div>
                                <div class="row-xz">
                                    <div class="w1">Auto</div>
                                    <div class="w2">
                                        <el-select v-model="select.type" placeholder="请选择表计类型"
                                            @change="getMeters(select.type)">
                                            <el-option label="计量表" value="1"></el-option>
                                            <el-option label="虚拟表" value="3"></el-option>
                                            <el-option label="离线表" value="4"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="w3">
                                        <el-select v-model="select.meterId" placeholder="请选择表计">
                                            <el-option v-for="item in meters" :key="item.value" :label="item.name"
                                                :value="item.id" />
                                            <el-option label="未选择" value="-1"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="w4">
                                        <span class="pointer c-main underline" @click="addMeter">添加</span>
                                    </div>
                                </div>
                                <div class="row" v-for="(item, index) in addForm.items" :key="index">
                                    <div class="td w1">{{ item.equation }}</div>
                                    <div class="td w2">{{ item.typeName }}</div>
                                    <div class="td w3">{{ item.gaugesName }}</div>
                                    <div class="td w4">
                                        <span class="pointer c-red underline" @click="removeMeter(item)">删除</span>
                                    </div>
                                </div>
                            </div>
                            <el-pagination @current-change="handleCurrentChange"  v-model:current-page="pageInfo.pageNum"
                                layout="prev, pager, next, jumper,total" :total="pageInfo.total" :page-size="pageInfo.pageSize">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="btngroup">
                        <button class="ann confirm" @click="onSubmit">确定</button>
                        <button class="ann back" @click="addPop = false">返回</button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import {
  getEquipList,
  getEnergyList,
  getMeterList,
  getVirtualMeterList,
  getOutlineMeterList,
} from "@/utils/common";
import {
  virtualMeterList,
  delVirtualMeter,
  addVirtualMeter,
  editVirtualMeter,
} from "@/api/meter";
export default {
  data() {
    return {
      editId: -1,
      energyTypeList: [],
      equimentId: "",
      equimentName: "未选择",
      meters: [],
      equiments: [],
      tableData: [],
      pageInfo: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      addPageInfo: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      loading: false,
      addPop: false,
      addForm: {
        name: "",
        type: "",
        biaodashi: "",
        shebei: [],
        miaoshu: "",
        items: [],
      },
      select: {
        type: "",
        meterId: "",
      },
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
  },
  created() {
    this.equiments = getEquipList();
  },
  mounted() {},
  watch: {},
  methods: {
    handleEdit(index, row) {
      (this.addForm = {
        name: "",
        type: "",
        biaodashi: "",
        shebei: [],
        miaoshu: "",
        items: [],
      }),
        (this.select = {
          type: "",
          meterId: "",
        });
      this.addForm.name = row.name;
      this.addForm.type = row.powerCategoryId;
      this.addForm.miaoshu = row.des;
      this.addForm.biaodashi = row.equation;
      if (row.deviceIds != "") {
        this.addForm.shebei = row.deviceIds.split(",").map(Number);
      }
      this.addForm.items = JSON.parse(row.item);
      this.editId = row.id;
      this.addPop = true;
      this.energyTypeList = getEnergyList();
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.deleteVirtualMeter(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.getVirtualMeters();
    },
    //判断数组非空
    shouldRenderTags(tag) {
      console.log(tag);
      return Array.isArray(tag) && tag.every((item) => item !== "");
    },
    //查询可作为公式的表
    getMeters(type) {
      if (type == "1") {
        this.meters = getMeterList();
      } else if (type == "3") {
        this.meters = getVirtualMeterList();
      } else {
        this.meters = getOutlineMeterList();
      }
    },
    //将表添加进公式
    addMeter() {
      let t = {};
      t.equation = "x" + String(this.addForm.items.length + 1);
      for (let i = 0; i < this.addForm.items.length; i++) {
        if (this.addForm.items[i].equation == t.equation) {
          t.equation = "x" + String(this.addForm.items.length + 2);
        }
      }
      t.type = this.select.type;
      if (t.type == "1") {
        t.typeName = "计量表";
      } else if (t.type == "3") {
        t.typeName = "虚拟表";
      } else {
        t.typeName = "离线表";
      }
      t.gaugesId = this.select.meterId;
      for (let i = 0; i < this.meters.length; i++) {
        if (this.meters[i].id == t.gaugesId) {
          t.gaugesName = this.meters[i].name;
        }
      }
      this.addForm.items.push(t);
    },
    //将表从公式中移除
    removeMeter(item) {
      this.$confirm("确认删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let index = this.addForm.items.indexOf(item);
          if (index !== -1) {
            this.addForm.items.splice(index, 1);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //获取虚拟表列表
    getVirtualMeters() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
        deviceId: this.equimentId,
      };
      if (this.equimentId == "-1") {
        delete param.deviceId;
      }
      virtualMeterList(param).then((res) => {
        if (res.data.code == 0) {
          this.pageInfo.total = res.data.total;
          this.tableData = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
    //删除具体虚拟表
    deleteVirtualMeter(id) {
      let param = {
        ids: String(id),
      };
      delVirtualMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
    //弹窗
    onAdd() {
      this.editId = -1;
      (this.addForm = {
        name: "",
        type: "",
        biaodashi: "",
        shebei: [],
        miaoshu: "",
        items: [],
      }),
        (this.select = {
          type: "",
          meterId: "",
        });
      this.addPop = true;
      this.energyTypeList = getEnergyList();
    },
    //添加/修改虚拟表
    onSubmit() {
      if (this.addForm.name == "") {
        this.$message.warning("请填写虚拟表名");
      } else if (this.addForm.leixing == "") {
        this.$message.warning("请填写能源类型");
      } else {
        let energyName = "";
        for (let i = 0; i < this.energyTypeList.length; i++) {
          if (this.energyTypeList[i].id == this.addForm.type) {
            energyName = this.energyTypeList[i].name;
          }
        }
        let param = {
          name: this.addForm.name,
          item: JSON.stringify(this.addForm.items),
          equation: this.addForm.biaodashi,
          deviceIds: this.addForm.shebei.join(","),
          des: this.addForm.miaoshu,
          powerCategoryId: this.addForm.type,
          powerCategoryName: energyName,
        };
        console.log(param);
        if (this.editId == -1) {
          addVirtualMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addPop = false;
              this.getVirtualMeters();
            }
          });
        } else {
          param.id = this.editId;
          editVirtualMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addPop = false;
              this.getVirtualMeters();
            }
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.pop .detail {
  padding-right: 15px;
}

.pop .lbox {
  width: 35%;
  margin-right: 4%;
}

.table * {
  font-size: 14px;
  color: #bcc8d4;
  text-align: center;
}

.table .thead {
  display: flex;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(22, 25, 44, 0) 0,
    rgba(22, 25, 44, 0.8) 50%,
    rgba(22, 25, 44, 0) 100%
  );
  position: relative;
}

.table .thead::before,
.table .thead::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(74, 79, 100, 0) 0,
    rgba(74, 79, 100, 1) 50%,
    rgba(74, 79, 100, 0) 100%
  );
}

.table .thead::before {
  top: 0;
}

.table .thead::after {
  bottom: 0;
}

.table .thead .th {
  line-height: 40px;
}

.table .row-xz {
  padding: 10px 5px;
}

.table .row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.table .td {
  background-color: #343752;
  line-height: 24px;
  padding: 8px 5px;
}

.table .w1 {
  width: 20%;
}

.table .w2 {
  width: 30%;
}

.table .w3 {
  width: 30%;
}

.table .w4 {
  width: 20%;
}

.table .row-xz {
  display: flex;
  align-items: center;
}

.table .row-xz .el-select {
  width: 85%;
  margin: 0 auto;
}
</style>
